var generic = generic || {};

generic.forms = {
    select : {
        addOption:  function(args) {
            if (!args) return;
            var val = args.value;
            var label = args.label || val;
            var opt = '<option value="' + val + '">' + label + '</option>';

            args.menuNode.append(opt);
        },
        setValue: function(args) {
            var idx = 0;
            for (var i=0, len=args.menuNode[0].options.length; i<len; i++) {
                if (args.value == args.menuNode[0].options[i].value) {
                    idx = i;
                    break;
                }
            }
            args.menuNode[0].selectedIndex = idx;
        }
    }
};

$("input[name*='PHONE2']").live('input || keyup || mouseup || change', function(event){
    var value = $(this).val();
    var pos;
    if(value.substr(0, 2) == "04") {
        value = value.replace('04','614');
        $(this).val(value);
        setCaretPosition($(this).attr("id"),3);
    } else if((pos = value.indexOf("-")) != -1) {
        value = value.replace(/-/g,"");
        $(this).val(value);
        setCaretPosition($(this).attr("id"),pos);
    }
});

function setCaretPosition(elemId, caretPos) {
    var elem = document.getElementById(elemId);
    if(elem != null) {
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}